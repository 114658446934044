body{
  font-family: sans-serif;
}


.App{
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

label{
  display: block;
  margin-top: 5px;
  font-weight: 800;
}

input{
  width: 400px;
  padding: 6px 12px;
  display: block;
  background-color: #fff;
  color: #444;
  border: 1px solid rgb(174, 172, 172);
  border-radius: 4px;
}

.formControl{
  margin-bottom: 20px;
}
button{
  padding: 5px 10px;
}

.error{
  color: crimson;
}

.submit-button{
  border-radius: 5px;
  border: 1px solid rgb(150, 147, 147);
  margin-top: 0.5rem;
}